<template>
  <div class="content">
    <div class="infoTop">
      <p>已选择<span>“{{ curText }}”</span></p>
      <p class="RemindersText">根据国家实名制要求，请准确提供身份证信息</p>
    </div>
    <div class="infoContent">
      <!--            5G商品选择-->
      <div v-if="isSel5G" class="Sel5GWrap">
        <div class="RatesSelWrap">
          <p class="RatesSelTitle">入网首月资费</p>
          <div class="RatesSel">
            <div
              v-for="(item,index) in RatesSelList"
              :key="index"
              class="RatesSelContent"
              :class="SelRateId === item.feeCode ? 'RatesSelContent_s' : '' "
              @click="RatesSel(item)"
            >
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="van-hairline--bottom" />
        <div class="RatesNoteWrap">
          <p class="RatesNoteTitle">详情描述：</p>
          <div>{{ RatesNote }}</div>
        </div>
      </div>
      <div class="UserInfo">
        <div class="information">
          <p class="informationTitle">姓名：</p>
          <input v-model="form.name" class="informationInp" placeholder="请输入身份证证件姓名" style="width: 75%;" type="text">
        </div>
        <div class="van-hairline--bottom" />
        <div class="information">
          <p class="informationTitle">身份证 ：</p>
          <input v-model="form.idEntityNo" class="informationInp" placeholder="请输入身份证号" style="width: 75%;" type="text">
        </div>
        <div class="van-hairline--bottom" />
        <div class="information">
          <p class="informationTitle">联系电话：</p>
          <input v-model="form.mobile" class="informationInp" placeholder="请输入联系电话" style="width: 60%;" type="number">
        </div>
        <div class="van-hairline--bottom" />
        <div class="information">
          <p class="informationTitle">验证码：</p>
          <input
            v-model="form.code"
            class="informationInp"
            placeholder="请输入验证码"
            maxlength="4"
            style="width: 45%;"
            type="number"
          >
          <button class="codeTextStyle" :style="{color:codeColor}" :disabled="disabled" @click="codeTextClick">
            {{ codeText }}
          </button>
        </div>
      </div>
      <div class="AddressInfo">
        <div class="AddressTitle">
          <p class="AddressTitle1">请填写配送地址</p>
          <p class="AddressTitle2"><span>(支持全国配送，新疆，西藏仅限省内配送)</span></p>
        </div>

        <div class="AddressInfoContent">
          <div class="information">
            <p class="informationTitle">所在地区：</p>
            <div class="informationDiv" style="width: 75%" @click="ChoiceProvinceCity">
              <span :style="{'color':informationDivColor}">{{ form.address }}</span>
              <van-icon name="arrow" color="#999" size="20" />
            </div>
          </div>
          <div class="van-hairline--bottom" />
          <div class="information">
            <input v-model="form.numberText" style="width: 100%" class="informationInp" placeholder="街道/镇+村/小区/写字楼+门牌号">
          </div>
        </div>
      </div>
      <div class="SelectionNumberInfo">
        <p class="selNumber2">请选择号码</p>
        <div class="information">
          <p class="informationTitle">号码归属：</p>
          <div class="informationDiv" style="width: 75%" @click="ChoiceAttribution">
            <span :style="{'color':informationDivColor2}">{{ form.cardAddress }}</span>
            <van-icon name="arrow" color="#999" size="20" />
          </div>
        </div>
        <div class="information">
          <p class="informationTitle">选择号码：</p>
          <div class="informationDiv" style="width: 75%" :style="{'color':informationDivColor3}" @click="selNumber">
            {{ form.selNumber }}
          </div>
        </div>
      </div>
      <div class="UsersServicesAgreement">
        <img :src="UsersServicesImg" alt="" @click="isSel">
        <div>
          <span>我已阅读并同意</span>
          <span class="serviceContent" @click="AboutInfo">《关于客户个人信息收集、使用规则的公告》</span>
        </div>
      </div>
    </div>
    <div class="submitBtn" @click="infoSubmit">立即提交</div>
    <div class="infoTips">
      <p>
        <span>请保持联系号码畅通，我们可能随时与您联系。本次为阶段性优惠活动，
          数量有限，联系电话无人接听或恶意下单情况，将不予发货。</span>
      </p>
      <p>
        <span>
          国际/港澳台漫游和国际/港澳台长途功能将于号码激活时同
          步生效，不使用不会产生费用哦
        </span>
      </p>
    </div>

    <!--        选择号码的弹窗-->
    <van-dialog
      v-model="numberDialog"
      :show-cancel-button="false"
      :show-confirm-button="false"
    >
      <div class="dia_box">
        <div class="dai_title">
          <span>请您选择号码</span>
          <div class="cods" @click="offShowModify">
            <van-icon name="cross" />
          </div>
        </div>
        <div class="cardListWrap">
          <div class="informationInp">
            <input v-model="ChoiceNumber" placeholder="生日，幸运数字等" type="number">
            <van-icon style="margin-top: 16px" name="search" @click="AnotherBatch(0)" />
          </div>
          <div v-if="cardList.length==0" class="cardList">
            <p class="isCardList">{{ CardText }}</p>
          </div>
          <ul v-else class="cardList">
            <li
              v-for="(item,index) in cardList"
              :key="index"
              class="cardListContent"
              @click="cardListContent(item)"
              v-html="KeyRegExp(item,ChoiceNumber)"
            />
          </ul>
        </div>
        <div class="dia_btn">
          <div @click="AnotherBatchBtn">换一批</div>
        </div>
      </div>
    </van-dialog>
    <!--        选择区县的弹窗-->
    <van-popup v-model="isProvinceCity" position="right" :style="{ height: '100%' }">
      <div class="listBox">
        <div class="listAddress">
          <ul>
            <li
              v-for="(item,key,index) in areaList"
              :key="index"
              :class="{areaColor:colorIndex===index}"
              class="listProvince"
              @click="clickProvince(item,index)"
            >
              {{ key }}
              <div class="van-hairline--bottom" />
            </li>
          </ul>
        </div>
        <div class="listAddress">
          <ul>
            <li
              v-for="(item,key,index) in currentCity"
              :key="index"
              :class="{areaColor:colorIndex1===index}"
              class="listCity"
              @click="clickCity(item,index)"
            >
              {{ key }}
              <div class="van-hairline--bottom" />
            </li>
          </ul>
        </div>
        <div class="listAddress">
          <ul>
            <li
              v-for="(item,key,index) in currentDistrict"
              :key="index"
              class="listDistrict"
              @click="clickDistrict(item,index)"
            >
              {{ item.districtName }}
              <div class="van-hairline--bottom" />
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
    <!--        归属地弹窗-->
    <van-popup v-model="isAttribution" position="right" :style="{ height: '100%' }">
      <div class="listBox">
        <div class="listAddress">
          <ul>
            <li
              v-for="(item,key,index) in numberAttribution"
              :key="index"
              :class="{areaColor:colorIndex===index}"
              class="listProvince"
              @click="clickAttributionProvince(item,index)"
            >{{ key }}
            </li>
          </ul>
        </div>
        <div class="listAddress">
          <ul>
            <li
              v-for="(item,key,index) in currentAttributionCity"
              :key="index"
              class="listCity"
              @click="clickAttributionCtiy(item,index)"
            > {{ key }}
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
    <div v-if="isError" class="errorDialogBox">
      <div class="errorDialog" :style="{height:height}">
        <div v-if="isErrorDialog === 0" class="errorDialogContent">
          <img
            class="errorImg"
            src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-04/16/yuelvhuir328WH51xa1607068823.png"
            alt=""
          >
          <p class="errorText">{{ errorText }}</p>
        </div>
        <div v-else-if="isErrorDialog === 1" class="SearchNumber">
          <div style="padding:25px 15px 0 ">
            <p class="SearchNumberP">请您选择号码</p>
            <div class="cardListWrap">
              <div class="informationInp">
                <input v-model="ChoiceNumber" placeholder="生日、幸运数字等" type="number">
                <img v-if="isSech" :src="numberCardImg" alt="" @click="AnotherBatch(0)">
                <img v-else :src="numberCardImg" alt="" @click="delNumber()">
              </div>
              <div v-if="cardList.length === 0" class="cardList">
                <p class="isCardList">{{ CardText }}</p>
              </div>
              <ul v-else class="cardList">
                <li
                  v-for="(item,index) in cardList"
                  :key="index"
                  class="cardListContent"
                  @click="cardListContent(item)"
                  v-html="KeyRegExp(item,ChoiceNumber)"
                />
              </ul>
            </div>
          </div>
          <div class="van-hairline--bottom" />
          <div v-if="cardList.length!=0" class="dia_btn">
            <p @click="AnotherBatchBtn">换一批</p>
          </div>
        </div>
        <div v-else-if="2" class="ClientIndividualService">
          <p class="pOne">关于客户个人信息收集、使用规则的公告</p>
          <p class="p1">尊敬的客户：</p>
          <p class="p2">
            根据
            <span>《中华人民共和国反恐怖主义法》</span>、
            <span>《全国人民代表大会常务委员会关于加强网络信息保护的决定》</span> 、
            <span>《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）</span>和
            <span>《电话用户真实身份信息登记规定》（工业和信息化部令第25号）</span>
            等国家法律法规的要求，客户在中国联合网络通信有限公司各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、移动电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。
          </p>
          <p class="p2">
            <span>如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。</span>
          </p>
          <p class="p3">
            为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：
          </p>
          <p class="p4">(a)事先获得客户的明确授权；</p>
          <p class="p4">(b)根据有关的法律法规要求；</p>
          <p class="p4">(c)按照相关司法机关和/或政府主管部门的要求；</p>
          <p class="p4">(d)为维护社会公众的利益所必需且适当；</p>
          <p class="p4">(e)为维护我公司或客户的合法权益所必需且适当。</p>
          <p class="p5">中国联合网络通信有限公司</p>
        </div>
        <div v-else-if="3" class="ClientNetworkService" />
      </div>
      <div class="cloneBtn" @click="cloneErrorDialog">×</div>
    </div>
  </div>
</template>

<script>
import {
  Area,
  Checkbox,
  CheckboxGroup,
  Dialog,
  Icon,
  popup,
  Toast
} from 'vant'

Vue.use(Toast)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Area)
  .use(Dialog)
  .use(Icon)
  .use(popup)

import {
  getCardVerification,
  getCardVerificatio,
  getProvinceCity,
  getCardAttribution,
  getCardList,
  get5GRates,
  setInfo
} from '@/services/comeOn'
import Vue from 'vue'

export default {
  name: 'InfoFill',
  data() {
    return {
      isError: false, // 错误弹窗
      errorText: '', // 错误文字
      isErrorDialog: 0, // 是否是错误弹窗
      curText: '',
      goodsId: '', // 商品id
      isSel5G: false, // 5G说明
      RatesNote: '', // 5G资费说明
      RatesSelList: [], // 5G资费选择内容
      RatesId: '', // 5GID
      SelRateId: '', // 5G选中样式
      height: '134px',
      form: {
        name: '', // 名称
        idEntityNo: '', // 身份证
        mobile: '', // 手机号
        code: '', // 验证码
        address: '请选择区/县', // 所在地区
        cardAddress: '请选择区/县', // 号码归属地
        numberText: '', // 详细地址
        selNumber: '请选择号码'// 选中的号码
      },
      address: { // 所在地区码
        provinceCode: '',
        cityCode: '',
        districtCode: ''
      },
      cardAddress: { // 号码归属地
        provinceCode: '',
        cityCode: ''
      },
      informationDivColor: '#cbcbcb',
      informationDivColor2: '#cbcbcb',
      informationDivColor3: '#999',
      codeColor: '#CB9C50',
      codeText: '获取验证码',
      codeNumber: 60,
      disabled: false,
      checked: true,
      captchaId: '', // 校验流水号
      isProvinceCity: false,
      isAttribution: false,
      areaList: [], // 省市
      numberAttribution: [], // 号码归属地
      currentCity: {}, // 当前市
      currentDistrict: {}, // 当前区县
      currentAttributionCity: {}, // 当前市
      // 选中变色
      colorIndex: 0,
      colorIndex1: 0,
      numberDialog: false, // 选择号码弹窗
      cardList: [], // 选号列表
      cardLists: [],
      isSech: true,
      numberCardImg: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-04/16/yuelvhuiqVcwZgl1gE1607072172.png', // 号码弹窗图标
      page: 0, // 选号页码
      ChoiceNumber: '', // 选号内容
      CardText: '请输入生日，幸运数字进行搜索', // 选号提示内容
      UsersServicesImg: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-04/19/yuelvhui3Bha6TUMHB1607082234.png',
      isSelData: true// 是否选择
    }
  },
  watch: {
    // 手机号
    'form.mobile': function(newVal, oldVal) {
      if (newVal.length > 10) {
        this.form.mobile = newVal.substring(0, 11)// 截取前4位
      }
    },
    // 短信验证码
    'form.code': function(newVal, oldVal) {
      if (newVal.length > 3) {
        this.form.code = newVal.substring(0, 4)// 截取前4位
      }
    },
    // 选号搜索
    'ChoiceNumber': function(newVal, oldVal) {
      // this.isSech = false;
      // this.numberCardImg = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-04/17/yuelvhuiuGHU65G64G1607073118.png';
      // if(this.ChoiceNumber == ''){
      //     this.isSech = true;
      //     this.numberCardImg = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-04/16/yuelvhuiqVcwZgl1gE1607072172.png';
      // }
    }
  },
  created() {
    // 0免流版大王卡1极速版腾讯王卡
    this.cur = this.$route.query ? Number(this.$route.query.cur) : Number(this.$route.query.cur)
    this.cur === 1 ? this.isSel5G = true : this.isSel5G = false
    this.cur === 1 ? this.get5GRatesContent() : ''
    //    选中的商品名称
    this.curText = this.$route.query ? this.$route.query.goodName : this.$route.query.goodName
    //    商品id
    this.goodsId = this.$route.query ? this.$route.query.goodsId : this.$route.query.goodsId
    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false)
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady)
        document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady)
      }
    } else {
      this.onBridgeReady()
    }
  },
  mounted() {
    this.getProvinceCity()// get省市
    this.selRegion()// get号码归属地
  },
  methods: {
    onBridgeReady() {
      // eslint-disable-next-line no-undef
      WeixinJSBridge.call('hideOptionMenu')
    },
    // get验证码
    codeTextClick() {
      const that = this
      if (that.form.idEntityNo === '') {
        Toast('请填写身份证号码')
        return false
      } else if (that.form.mobile === '') {
        Toast('请填写手机号')
        return false
      } else {
        getCardVerification({
          certId: that.form.idEntityNo, // 身份证号
          contactNum: that.form.mobile// 手机号
        }).then(res => {
          if (Number(res.code) === 200) {
            Toast(res.msg)
            that.disabled = true// 禁用按钮
            that.codeColor = '#ccc'
            that.codeText = that.codeNumber + 's'
            const Interval = setInterval(function() {
              that.codeText = that.codeNumber + 's重新发送';
              (that.codeNumber)--
              if (that.codeNumber === 0) {
                clearInterval(Interval)
                that.codeText = '获取验证码'
                that.disabled = false// 启用按钮
                that.codeColor = '#cb9c50'
                that.codeNumber = 60
              }
            }, 1000)
          } else {
            Toast(res.msg)
          }
        })
      }
    },
    // 选择省市
    ChoiceProvinceCity() {
      this.isProvinceCity = true
      //    修改颜色
      this.informationDivColor = '#000'
    },
    // 选择省
    clickProvince(item, index) {
      this.currentCity = item
      this.colorIndex = index
      this.currentDistrict = (this.currentCity)[Object.keys((this.currentCity))[0]]// 县区
    },
    // 选择市
    clickCity(item, index) {
      this.currentDistrict = item
      this.colorIndex1 = index
    },
    // 选择区/县
    clickDistrict(item, index) {
      this.address = {
        provinceCode: item.provinceCode,
        cityCode: item.cityCode,
        districtCode: item.districtCode
      }
      this.form.address = item.provinceName + ',' + item.cityName + ',' + item.districtName// 所在地区
      this.isProvinceCity = false
    },
    // get省市
    getProvinceCity() {
      getProvinceCity().then(res => {
        if (Number(res.code) === 200) {
          this.areaList = res.data// 默认全部地区
          this.colorIndex = 0
          this.currentCity = (res.data)[Object.keys((res.data))[0]]// 市
          this.currentDistrict = (this.currentCity)[Object.keys((this.currentCity))[0]]// 县区
        } else {
          this.height = '134px'
          this.isErrorDialog = 0
          this.errorText = res.msg
          this.isError = true
        }
      })
    },
    // 选号
    selNumber() {
      if (this.form.cardAddress === '请选择区/县') {
        Toast('请选择区/县')
        return false
      } else {
        // this.numberDialog = true;
        this.isErrorDialog = 1
        this.isError = true
        this.height = 'auto'
        // 默认展示一些数据
        this.AnotherBatch(1)
        // 修改颜色
        this.informationDivColor3 = '#000'
      }
    },
    // 选择归属地
    ChoiceAttribution() {
      this.isAttribution = true
      // 修改颜色
      this.informationDivColor2 = '#000'
    },
    // 选择归属地省
    clickAttributionProvince(item, index) {
      this.currentAttributionCity = item
      this.colorIndex = index
    },
    // 选择归属地市
    clickAttributionCtiy(item, index) {
      this.cardAddress = { // 号码归属地
        provinceCode: item.essProvinceCode,
        cityCode: item.essCityCode
      }
      this.form.cardAddress = item.provinceName + ',' + item.cityName
      this.isAttribution = false
    },
    //    选择号码归属地
    selRegion() {
      getCardAttribution().then(res => {
        if (Number(res.code) === 200) {
          this.numberAttribution = res.data
          this.currentAttributionCity = (res.data)[Object.keys((res.data))[0]]
        } else {
          this.height = '134px'
          this.isErrorDialog = 0
          this.errorText = res.msg
          this.isError = true
        }
      })
    },
    // get选号（选号搜索）
    AnotherBatch(type) {
      if (type === 0) { // 搜索查询数据
        if (this.ChoiceNumber === '') {
          Toast('请输入生日，幸运数字')
          return
        } else {
          this.getCardNumberList()
        }
      }
      if (type === 1) {
        this.getCardNumberList()
      }
    },
    // get选号数据
    getCardNumberList() {
      getCardList({
        provinceCode: this.cardAddress.provinceCode, // 省代码
        cityCode: this.cardAddress.cityCode, // 市代码
        goodsId: this.goodsId, // 商品id
        searchValue: this.ChoiceNumber// 关键字
      }).then(res => {
        if (Number(res.code) === 200) {
          if ((res.data).length === 0) {
            this.cardList = []// 滞空
            this.CardText = '抱歉没有匹配的号码'
            // 隐藏换一批按钮
            return false
          } else {
            this.cardLists = res.data.numArray// 匹配号码全部列表
            this.cardList = (this.cardLists)[this.page]// 默认展示第一组数据
          }
        } else {
          this.height = '134px'
          this.isErrorDialog = 0
          this.errorText = res.msg
          this.isError = true
        }
      })
    },
    // 关键字高亮显示
    KeyRegExp(val, keyword) {
      val = val + ''
      if (val.indexOf(keyword) !== -1 && keyword !== '') {
        return val.replace(keyword, '<span style="color:red">' + keyword + '</span>')
      } else {
        return val
      }
    },
    // 换一批,下一页
    AnotherBatchBtn() {
      this.page++
      if (this.page > (this.cardLists).length - 1) {
        // Toast('没有更多匹配号码');
        this.cardList = []
        this.CardText = '无号码'
        this.page = 0
      } else {
        this.cardList = (this.cardLists)[this.page]// 更新数据
      }
    },
    // 选择号码
    cardListContent(item) {
      // this.numberDialog = false;//关闭弹窗
      this.isError = false
      this.form.selNumber = item// 选中的号码
    },
    // 关闭选号窗口
    offShowModify() {
      this.numberDialog = false
      this.ChoiceNumbe = ''// 清楚关键字
    },
    //    立即提交
    infoSubmit() {
      // 请求流验码
      const that = this
      if (this.form.idEntityNo === '') {
        Toast('请填写身份证号码')
        return false
      } else if (this.form.mobile === '') {
        Toast('请填写手机号')
        return false
      } else if (this.isSelData === false) {
        Toast('请阅读并同意用户协议')
        return false
      } else {
        getCardVerificatio({
          certId: that.form.idEntityNo, // 身份证
          contactNum: that.form.mobile, // 手机号
          captcha: that.form.code// 验证码
        }).then(res => {
          // 清空验证码
          this.form.code = ''
          if (Number(res.code) === 200) {
            that.captchaId = res.data.captchaId
            setInfo({
              phoneNum: this.form.selNumber, // 所选号码
              contactNum: this.form.mobile, // 联系电话
              captchaId: this.captchaId, // 流验证码
              certName: this.form.name, // 姓名
              certNo: this.form.idEntityNo, // 身份证
              provinceCode: this.cardAddress.provinceCode, // 归属地省代码
              cityCode: this.cardAddress.cityCode, // 归属地市代码
              postProvinceCode: this.address.provinceCode, // 邮寄地省代码
              postCityCode: this.address.cityCode, // 邮寄地市代码
              postDistrictCode: this.address.districtCode, // 邮寄地区代码
              postAddr: this.form.numberText, // 邮寄详细地址
              goodsId: this.goodsId, // 商品id
              firstMonthFee: this.RatesId// 5G首月资费
            }).then(res => {
              if (Number(res.code) === 200) {
                this.$router.push({
                  path: '/SubmitSuccess',
                  query: {
                    cur: this.cur
                  }
                })
              } else {
                this.height = '134px'
                this.isErrorDialog = 0
                this.errorText = res.msg
                this.isError = true
              }
            })
          } else {
            this.height = '134px'
            this.isErrorDialog = 0
            this.errorText = res.msg
            this.isError = true
          }
        })
      }
    },
    // 客户入网协议
    lookServeice() {
      this.isErrorDialog = 3
      this.height = '350px'
      this.isError = true
    },
    // 关于信息
    AboutInfo() {
      this.isErrorDialog = 2
      this.height = '350px'
      this.isError = true
    },
    // get5G资费详情
    get5GRatesContent() {
      get5GRates().then(res => {
        if (Number(res.code) === 200) {
          this.RatesSelList = res.data
          // 默认选中第一个
          this.RatesId = (this.RatesSelList)[0].feeCode// 套餐id
          this.RatesNote = (this.RatesSelList)[0].explain// 套餐详情
          this.SelRateId = (this.RatesSelList)[0].feeCode// 5G选中样式
        } else {
          this.height = '134px'
          this.isErrorDialog = 0
          this.errorText = res.msg
          this.isError = true
        }
      })
    },
    // 5G套餐选择
    RatesSel(item) {
      this.RatesNote = item.explain// 套餐详情
      this.RatesId = item.feeCode// 套餐id
      this.SelRateId = item.feeCode// 5G选中样式
    },
    //    关闭错误弹窗
    cloneErrorDialog() {
      this.isError = false
      this.height = 'auto'
      this.ChoiceNumber = ''
    },
    //    选号弹窗中删除所填
    delNumber() {
      this.ChoiceNumber = ''
    },
    //    用户选择协议
    isSel() {
      this.isSelData = !this.isSelData
      if (this.isSelData === true) {
        this.UsersServicesImg = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-04/19/yuelvhui3Bha6TUMHB1607082234.png'
      } else if (this.isSelData === false) {
        this.UsersServicesImg = 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-04/19/yuelvhuiAnXRpmfP5g1607082417.png'
      }
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  margin: 0;
  top: 0;
  background-color: #F7F7F7;
  text-align: left;
}

.content {
  position: absolute;

  .infoTop {
    padding: 15px;
    font-size: 15px;

    p {
      font-size: 15px;
      color: #666;

      span {
        color: #D7B587;
      }
    }

    .RemindersText {
      margin-top: 14px;
      color: #666;
    }
  }

  .infoContent {
    width: 100%;

    .Sel5GWrap {
      background-color: #fff;
      padding: 15px;

      .RatesSelWrap {
        .RatesSelTitle {
          font-size: 15px;
          font-weight: 700;
        }

        .RatesSel {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-top: 16px;

          .RatesSelContent {
            position: relative;
            width: 30%;
            height: 30px;
            line-height: 30px;
            text-align: center;
            color: #999999;
            font-size: 12px;
            margin-right: 2%;
            background-color: #F4F5F7;
            border-radius: 5px;
            margin-bottom: 10px;

            .RatesSelMarking {
              position: absolute;
              bottom: 0;
              right: 0;
              width: 0;
              height: 0;
              border-bottom: 15px solid #1E97FA;
              border-left: 15px solid transparent;
            }

            .RatesSelMarkingDui {
              position: absolute;
              bottom: -9px;
              right: 1px;
              color: #fff;

              span {
                display: inline-block;
                font-size: 12px;
                transform: scale(0.5);
              }
            }
          }

          .RatesSelContent_s {
            border: 1px solid #D4AD6D;
            background-color: #FEF9F7;
            color: #D4AD6D;
          }
        }
      }

      .RatesNoteWrap {
        display: flex;
        padding-top: 10px;
        font-size: 12px;
        color: #999999;

        .RatesNoteTitle {
          width: 22%;
        }

        div {
          width: 75%;
        }
      }
    }

    .UserInfo {
      background-color: #fff;
      line-height: 32px;
      width: 100%;
    }

    .AddressInfo {
      .AddressTitle {
        padding: 12px 0 12px 12px;
        display: flex;

        .AddressTitle1 {
          color: #A4A4A4;
          font-size: 12px;
        }

        .AddressTitle2 {
          font-size: 12px;

          span {
            display: inline-block;
            color: rgb(253, 32, 58);
            transform: scale(0.9);
          }

        }
      }

      .AddressInfoContent {
        /*padding: 0 15px;*/
        background-color: #fff;
      }
    }

    .SelectionNumberInfo {
      .selNumber2 {
        padding: 15px;
        font-size: 13px;
      }
    }

    .UsersServicesAgreement {
      margin: 15px 0;
      padding: 0 15px;
      display: flex;

      img {
        width: 17px;
        height: 17px;
        margin-right: 5px;
      }

      .serviceContent {

        color: rgb(208, 96, 64);
      }
    }

    .information {
      display: flex;
      height: 55px;
      line-height: 55px;
      background-color: #fff;
      padding: 0 15px;

      .informationTitle {
        width: 25%;
        -webkit-text-size-adjust: none;
        font-size: 15px;
        text-align: left;
        color: #333333;
      }

      .informationDiv {
        font-size: 13px;

        span {
          display: inline-block;
          width: 90%;
          color: #999999;
        }
      }

      .informationInp {
        background: none;
        outline: none;
        border: none;
        font-size: 16px;
        color: #000;
      }

      .informationInp::-webkit-input-placeholder { /* WebKit browsers */
        color: #999;
        font-size: 15px;
      }

      .codeTextStyle {
        background: none;
        width: 99px;
        height: 31px;
        line-height: 31px;
        padding: 0;
        font-size: 15px;
        text-align: center;
        border: 1px solid #CB9C50;
        border-radius: 3px;
        margin-top: 12px;
      }
    }
  }

  .submitBtn {
    width: 90%;
    height: 50px;
    color: #fff;
    background-color: #D3AB69;
    margin: 0 auto;
    text-align: center;
    line-height: 50px;
    font-size: 20px;
    border-radius: 3px;
  }

  .infoTips {
    padding: 15px;
    text-align: center;
    color: #999;
    margin-top: 20px;

    p {
      line-height: 16px;
      font-size: 12px;

      span {
        transform: scale(0.9);
      }
    }

    p:nth-child(2) {
      margin-top: 10px;
    }
  }

  .dia_box {
    padding: 10px;

    .dai_title {
      padding: 10px 0;
      font-size: 17px;
      color: #333333;
      line-height: 16px;
      position: relative;

      span {
        font-size: 14px;
        color: #A4A4A4;
      }

      .cods {
        position: absolute;
        right: 10px;
        top: 6px;
      }
    }

    .cardListWrap {
      margin-bottom: 20px;
      border-bottom: 1px solid #ccc;

      .informationInp {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #ccc;
        padding-left: 10px;
        /*border-radius: 7px;*/
        height: 50px;
        width: 100%;

        input {
          width: 90%;
          height: 48px;
          color: #C16A44;
          font-size: 14px;
        }

        .informationInp::-webkit-input-placeholder { /* WebKit browsers */
          color: #CBCBCB;
          font-size: 14px;
        }
      }

      .isCardList {
        font-size: 16px;
        color: #C16A44;
      }

      .cardList {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;

        li {
          width: 45%;
          margin-left: 5%;
          margin-bottom: 10px;

          .cardListContent {

          }

          .search-text {
            color: red;
          }
        }
      }
    }

    .dia_btn {
      display: flex;
      text-align: center;

      div {
        flex: 11;
        font-size: 14px;
        font-weight: 500;
        border-radius: 6px;
        color: #EEAF5F;
      }
    }
  }

  .listBox {
    width: auto;
    height: auto;
    display: flex;
    font-size: 15px;
    border: 1px solid #999;
    box-shadow: 0 0 5px 0px #000;

    .listAddress {
      display: flex;
      box-shadow: 0 0 0.1rem 0 #ddd;

      .areaColor {
        color: #BA582E;
      }

      .listProvince, .listCity, .listDistrict {
        width: 82px;
        height: 45px;
        line-height: 45px;
        padding-left: 12px;
        margin: 0 2vw 0 1vw;
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

  }

  .errorDialogBox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(67, 67, 67, .8);

    .errorDialog {
      width: 348px;
      /*height: 134px;*/
      overflow-y: scroll;
      background-color: #fff;
      border-radius: 3px;
      margin: 100px auto 0 auto;

      .errorDialogContent {
        padding-top: 30px;
        text-align: center;

        .errorImg {
          position: absolute;
          width: 40px;
          height: 40px;
          left: 50%;
          margin-left: -20px;
        }

        .errorText {
          /*position: absolute;*/
          font-size: 16px;
          color: #333333;
          font-weight: 600;
          margin-top: 53px;
        }
      }

      .SearchNumber {
        .SearchNumberP {
          font-size: 15px;
          color: #999;
          margin-bottom: 19px;
        }

        .cardListWrap {
          margin-bottom: 20px;
          /*border-bottom: 1px solid #ccc;*/

          .informationInp {
            display: flex;
            flex-wrap: wrap;
            border: 1px solid #dcdcdc;
            padding-left: 10px;
            border-radius: 3px;
            height: 50px;
            width: 100%;

            input {
              width: 90%;
              height: 48px;
              color: #EEAF5F;
              font-size: 16px;
            }

            .informationInp::-webkit-input-placeholder { /* WebKit browsers */
              color: #999;
              font-size: 16px;
            }

            img {
              width: 20px;
              height: 20px;
              margin-top: 15px;
            }
          }

          .isCardList {
            font-size: 16px;
            color: #EEAF5F;
          }

          .cardList {
            display: flex;
            flex-wrap: wrap;
            padding: 10px 10px 0 10px;
            font-size: 19px;
            color: #333333;
            margin-top: 8px;
            font-family: 'PingFang SC Regular';

            li {
              width: 45%;
              margin-left: 5%;
              margin-top: 24px;
              font-weight: 600;

              .cardListContent {

              }
            }
          }
        }

        .dia_btn {
          display: flex;
          text-align: center;
          font-size: 16px;

          p {
            flex: 11;
            font-size: 16px;
            font-weight: 500;
            border-radius: 6px;
            color: #C26B45;
            line-height: 50px;
          }
        }
      }

      .ClientIndividualService {
        padding: 15px;
        color: #000;
        line-height: 25px;

        .pOne {
          text-align: center;
          font-size: 17px;
          color: #333333;
          font-weight: 700;
        }

        .p1 {
          font-size: 13px;
          margin-top: 36px;
        }

        .p2 {
          text-indent: 2em;

          span {
            color: red;
          }
        }

        .p3 {
          text-indent: 2em;
        }

        .p4 {
          text-indent: 4em;
        }

        .p5 {
          margin-top: 20px;
          text-align: right;
        }
      }

      .ClientNetworkService {

      }
    }

    .cloneBtn {
      position: absolute;
      width: 24px;
      height: 24px;
      margin-left: -12px;
      left: 50%;
      border-radius: 50%;
      text-align: center;
      margin-top: 42px;
      color: #fff;
      border: 1px solid #fff;
      line-height: 24px;
      font-size: 20px;
    }
  }

  .keyColor {
    color: red;
  }
}

/deep/ .van-icon {
  top: 5px;
}

/dee/ .keyColor {
  color: red;
}

</style>
